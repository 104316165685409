<template>
    <div class="bg">
      <div>
        <ul class="app-header-nav">
          <li
              :class="{ 'active':$route.query.id == item.id }"
              v-for="item in list"
              :key="item.id"
              @click="clickFn(item)"
          >
            <a :href="item.url">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "pcnav",
    data() {
      return {};
    },
    methods: {
      clickFn(item) {
        this.$router.push({ path: item.url, query: { id: item.id } });
      },
    },
    computed: {
      list() {
        return [
          { id: 0, name: "首页", url: "/" },
          { id: 1, name: "音乐制作", url: "/createMusic?id=1" },
          { id: 2, name: "曲谱排版", url: "/musicComposition?id=2" },
          { id: 3, name: "图书排版", url: "/bookComposition?id=3" },
          { id: 4, name: "出版印刷", url: "/plushing?id=4" },
          { id: 5, name: "曲谱库", url: "/musicScore?id=5" },
          { id: 6, name: "会员服务", url: "/membercenter?id=6" },
          { id: 7, name: "招业务员", url: "/recruit?id=7" },
          { id: 8, name: "关于我们", url: "/about?id=8" },
        ];
      },
    },
  };
  </script>

  <style scoped lang="less">
  .bg {
    background-color: #fff;
  }
  .app-header-nav {
    //height: 45px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 999;
    > li {
      text-align: center;
      line-height: 32px;
      height: 32px;
      width: 78px;
      display: inline-block;
      text-align-last: justify;
      // white-space: nowrap;
      cursor: pointer;
       >a {
        white-space: nowrap;
        font-size: 18px;
        height: 32px;
        line-height: 32px;
        
      }
      &:hover,
      &.active {
        > a {
          color: #27ba9b;
          border-bottom: 1px solid #27ba9b;
        }
      }
    }
  }

  </style>
