<template>
  <div>
    <nav class="pc-top">
      <ul class="container">
        <li @click="collect">收藏</li>
        <li @click="shareClick">分享有惊喜</li>
<!--        <li @click="collect">收藏本站</li>-->
<!--        <li @click="shareClick">分享本站 分享有惊喜哦</li>-->
        <!-- <li @click="homePage">设为首页</li> -->
        <li v-if="!token"><RouterLink to="/logon" >登录</RouterLink></li>
        <li v-if="!token"><router-link to="/regist" >注册</router-link></li>
        <li v-else>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              {{ userInfo.nickName||'用户' }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="goOUT"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </nav>
    <div class="middle container">
      <div class="middle-header">
        <el-image :src="baseUrl+src" class="header-image" @click="$router.push('/')"></el-image>
        <div class="middle-text">
          <img :src="bacUrl"/>
        </div>
        <div class="middle-wei">
          <div class="phoneStyle">
            <img src="../assets/topPhone.png" style="width:23px;height:23px;margin-top:-5px;"/>
            {{ bottomList.phone||''}}
          </div>
          <div class="QQstyle">
            <!-- <a href="https://wpa.qq.com/msgrd?v=3&uin=953311163&site=qqq&menu=yes" target="_blank" > -->
              <a @click="go(bottomList.tencent)" style="cursor: pointer;">
              <img src="../assets/topQQ2.png" style="width:23px;height:23px;margin-top: -6px;margin-right:3px;"/>
              <img src="../assets/topWeixin2.png" style="width:23px;height:23px;margin-top: -6px;margin-right: 9px;"/>{{ bottomList.tencent||'' }}
            </a>

            </div>
        </div>
      </div>
      <pcnav></pcnav>
    </div>
  </div>
</template>

  <script>
  import {
  bottomChange
} from "@/api/musicScore";
import { mapState,mapMutations } from "vuex";
import {getMusicBannerIndexPicApi} from '@/api/index/index'
import {qqlogin} from "@/api/login";
import {getMusicScore} from '@/api/musicScore'
export default {
  name: "pcheader",
  data() {
    return {
      bacUrl:'',
      visible: false,
      qqShow:1,
      bottomList: {},
      src: '',
      baseUrl:this.$image,
    };
  },
  methods: {
    qqlogin() {
      return qqlogin
    },
    ...mapMutations(['logout']),
    homePage(){
      const key = Object.keys(this.$route.query)[0] // 动态拿到路径的key值
      //路径+动态key+参数
      const pathValue = this.$route.path+'?'+Object.keys(this.$route.query)[0]+'='+this.$route.query[key]
      window.localStorage.setItem("isRouter", pathValue)
      this.$message({
        message: '设为首页成功!',
        type: 'success'
      });
    },
    collect(){
      alert('请使用浏览器的收藏功能快捷键(Ctrl/Cmd + D)收藏本页。');
    },
    goOUT(){
      this.logout()
      this.$router.push('/')
      this.$message.success('退出登录成功')
    },
    shareClick() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 100000, //回到底部
          left: 0,
          behavior: "smooth", //smooth 平滑；auto:瞬间
        });
      });
    },
    // 获取头部logo
    getLogo(){
      getMusicScore({plate: 21}).then(res=>{
        if(res.data.picUrl){
          this.src = res.data.picUrl
        }else {
          this.src = require('/src/assets/logo.jpg')
        }
      })
    },
    putKonge(Text){
      const oneText = Text.toString()
      console.log(oneText.replace(/' '/g ,''),'333');
      return oneText.replace(/ /g ,'')
    },
    isIOS(){
      return /iphone|iPad/i.test(navigator.userAgent)
    },
    go(qqNum){
        window.open('https://wpa.qq.com/msgrd?v=3&uin='+this.putKonge(qqNum)+'&site=qqq&menu=yes')
    }
    // QQhover(){
    //   this.qqShow=2;
    // },
    // QQOut(){
    //   this.qqShow=1;
    // }
  },
  created() {
    bottomChange().then((res) => {
      this.bottomList = res.data;
    });
    getMusicBannerIndexPicApi().then(res=>{
      this.bacUrl =  this.$image+res.data.picUrl
    })
    this.getLogo()
  },
  computed: {
    ...mapState(["token","userInfo"]),
  },
};
</script>

  <style scoped lang="less">
.pc-top {
  background-color: rgba(243, 243, 243, 1);
  ul {
    display: flex;
    height: 38px;
    justify-content: flex-end;
    align-items: center;
    li {
      padding: 0 0 0 15px;
      line-height: 1;
      color: rgba(80, 80, 80, 1);
      display: inline-block;
      font-size: 14px;
      &:hover {
        cursor: pointer;
        color: #409eff;
      }
      a:hover{
        cursor: pointer;
        color: #409eff;
      }
    }
  }
}
.middle {
  padding: 20px 0;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-left: 18.5%;
  // margin-right:18.5%;
  .middle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-image {
      width: 360px;
      height:150px;
      cursor: pointer;
    }
  }
  .middle-text {
    width: 47%;
    height:130px;
    margin-right: 5px;
    letter-spacing: 2px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    div {
      margin-right: 10%;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .middle-wei {
    text-align: center;
    width: 173px;
    height:61px;
    border-radius: 45px;
    font-size: 16px;
    font-weight: bolder;
    flex-shrink: 0;
    //box-shadow: 2px 2px 2px grey;
  }
  .phoneStyle{
    margin-top: 5px;
    font-size:19px;
  }
  .QQstyle{
    margin-top: 10px;
    font-size:19px;
  }
  .QQstyle:hover{
    color:blue;
    font-size:19px;
    text-decoration: underline;
    a{
      color:blue;
    }
  }
}
</style>
