import request from '@/service/index'
//账号密码登录
export const loginFn = (data) => {
    return request({
        url: '/login',
        method: 'post',
        data: data
    })
}

//手机验证码登录
export const loginPhone = (data) => {
    return request({
        url: '/loginByPhone',
        method: 'post',
        data
    })
}
//手机验证码登录注册
export const registerLoginPhone = (data) => {
    return request({
        url:'/registerLoginByPhone',
        method: 'post',
        data
    })
}
//获取用户会员详细信息
export const getUserDeatils = () => {
    return request({
        url: '/music/vip/vip',
        method: 'get',
    })
}

//验证是否已经登录
export function checkLogin() {
    return request({
        url: '/wechat/checkLogin',
        method: 'get'
    })
}
//qq登录
export function qqlogin() {
    return request({
        url: "/qq/login",
        method: 'get'
    })
}

//生成微信扫描二维码
export function wxLogin(params) {
    return request({
        url: '/wechat/login',
        method: 'get',
        params
    })
}
//QQ登录
export function QQloginFn(params) {
    return request({
        url: '/qq/login',
        method: 'get',
        params
    })
}

//qq扫码登录绑定手机号
export function qqbindPhone(data) {
    return request({
        url: "/qq/phoneNumberBind",
        method: 'post',
        data
    })
}
//qq扫码登录第一个接口
export function qqfirst(params) {
    return request({
        url: '/qq/callback',
        method: "get",
        params
    }

    )
}

//qq扫码登录传code和state
export function qqCodeCallback(data){
    return request({
        url:"/qq/callback",
        method:'post',
        data
    })
}
