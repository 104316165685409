<template>
  <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
  ></video-player>
</template>

<script>
export default {
  name: "myVideoPlayer",
  props: {
    src:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      playerOptions: {
        height: 200,
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.src // url地址
          }
        ],
        controlslist:"nodownload noremoteplayback",
        // liveui:true,
        poster: '', // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 显示时间分割线
          durationDisplay: true, // 显示总播放时间
          remainingTimeDisplay: true, // 显示剩余时间
          fullscreenToggle: true ,// 全屏按钮
          sekStep:10,
          progressControl: {
            seekBar: {
              enablePlayProgressBar: true,
              enableSeekBarTooltip: true,
              dragInterval: true // 检查 dragInterval 设置
            },
            playProgressBar: true,
          },
        },
        userActions: {
          click: true,
          doubleClick: true,
          hotkeys: true,
          keyboard: true,
          mousewheel: true,
          volume: true,
          fullscreen: true
        }
      },
    }
  },
  methods: {
    pauseVideo() {
      // 在这里调用播放器内部的方法来暂停视频播放
      this.$refs.videoPlayer.player.pause();  // 替换为你的播放器ref名称
    },
  },
}
</script>

<style scoped>

</style>
