import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    component: () => import('@/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/dashboard')
      },
      {
        path: '/createMusic',
        component: () => import('@/views/createMusic')
      },
      {
        path: '/musicComposition',
        component: () => import('@/views/musicComposition/index.vue')
      },
      {
        path: '/bookComposition',
        component: () => import('@/views/bookComposition')
      },
      {
        path: '/plushing',
        component: () => import('@/views/plushing'),
      },
      {
        path: '/musicScore',
        component: () => import('@/views/musicScore')
      },
      {
        path: '/membercenter',
        component: () => import('@/views/membercenter')
      },
      {
        path: '/recruit',
        component: () => import('@/views/recruit')
      },
      {
        path: '/about',
        component: () => import('@/views/about')
      },
      {
        path: '/logon',
        component: () => import('@/views/logon')
      },
      {
        path: '/regist',
        component: () => import('@/views/regist')
      },
      {
        path: 'musicScore/musicDetails',
        component: () => import('@/views/musicScore/musicDetails.vue')
      },
      {
        path: '/forget',
        component: () => import('@/views/forget')
      },
      {
        path: '/shopingMall',
        component: () => import('@/views/shopingMall')
      },
      {
        path: '/videoPlaying',
        component: () => import('@/views/createMusic/videoPlaying.vue')
      },
      {
        path: '/musicScoreDetails',
        component: () => import('@/views/musicScore/musicScoreDetails')
      },
    ]
  },
  {
    path: '/qqLogin',
    component: () => import('@/views/QQLogin')
  },
  {
    path: '*',
    component: () => import('@/views/404'),
  },
  {
    path: '/phone',
    component: () => import('@/layout'),
    children: [
      {
        path: '',
        component: () => import('@/views/phone/home/index.vue')
      },
      {
        path: 'createMusic',
        component: () => import('@/views/phone/createMusic-phone')
      },
      {
        path: 'musicComposition',
        component: () => import('@/views/phone/musicComposition-phone'),
      },
      {
        path: 'musicComposition/detail',
        component: () => import('@/views/phone/musicComposition-phone/musicCompositionDetail-phone')
      },
      {
        path: 'bookComposition',
        component: () => import('@/views/phone/bookComposition-phone')
      },
      {
        path: 'bookComposition/detail',
        component: () => import('@/views/phone/bookComposition-phone/bookCompositionDetail-phone')
      },
      {
        path: 'plushing',
        component: () => import('@/views/phone/plushing-phone'),
      },
      {
        path: 'plushing/more',
        component: () => import('@/views/phone/plushing-phone/more')
      },
      {
        path: 'musicScore',
        component: () => import('@/views/phone/musicScore-phone'),
      },
      {
        path: 'musicScore/classify',
        component: () => import('@/views/phone/musicScore-phone/classifyScore')
      },
      {
        path: 'musicScore/search',
        component: () => import('@/views/phone/musicScore-phone/search')
      },
      {
        path: 'musicScore/more',
        component: () => import('@/views/phone/musicScore-phone/more')
      },
      {
        path: 'membercenter',
        component: () => import('@/views/phone/membercenter-phone'),
        children: [
          {
            path: '',
            component: () => import('@/views/phone/membercenter-phone/home.vue')
          },
          {
            path: 'BalanceRecharge',
            component: () => import('@/views/phone/membercenter-phone/BalanceRecharge.vue')
          },
          {
            path: 'joinVip',
            component: () => import('@/views/phone/membercenter-phone/joinVip.vue')
          },
        ]
      },
      {
        path: 'recruit',
        component: () => import('@/views/phone/recruit-phone'),
      },
      {
        path: 'about',
        component: () => import('@/views/phone/about-phone'),
      }
    ]
  },
  {
    path: '/phone/vedioDetails',
    component: () => import('@/views/phone/createMusic-phone/vedioDetails.vue')
  },
  {
    path:'/phone/myOtherList',
    component: () => import('@/views/phone/membercenter-phone/otherList.vue')
  },
  {
    path: '/phone/moreDetails',
    component: () => import('@/views/phone/createMusic-phone/more.vue')
  },
  {
    path: '/phone/coupon',
    component: () => import('@/views/phone/membercenter-phone/cupon.vue')
  },
  {
    path: '/phone/customized',
    component: () => import('@/views/phone/membercenter-phone/customized.vue')
  },
  {
    path: '/phone/FreeSpectrum',
    component: () => import('@/views/phone/membercenter-phone/FreeSpectrum.vue')
  },
  {
    path: '/phone/inviteNew',
    component: () => import('@/views/phone/membercenter-phone/InviteNew.vue')
  },
  {
    path: '/phone/login',
    component: () => import('@/views/phone/login')
  },
  {
    path: '/phone/login/captcha',
    component: () => import('@/views/phone/login/Captcha')
  },
  {
    path: '/phone/musicScore/detail',
    component: () => import('@/views/phone/musicScore-phone/musicScoreDetail')
  },
  {
    path: '/phone/UploadWorks',
    component: () => import('@/views/phone/membercenter-phone/UploadWorks.vue')
  },
  {
    path:'/phone/UploadWorksList',
    component:()=> import('@/views/phone/membercenter-phone/uploadWorkList.vue')
  },
  {
    path: '/phone/Collection',
    component: () => import('@/views/phone/membercenter-phone/Collection.vue')
  },
  {
    path:'/phone/exchange',
    component:()=>import('@/views/phone/membercenter-phone/exchange.vue')
  },
  {
    path:'/phone/submitSpe',
    component:()=>import('@/views/phone/membercenter-phone/submitFreeSpe.vue')
  },
  {
    path:'/phone/integral',
    component:()=>import('@/views/phone/membercenter-phone/integral.vue')
  },
  {
    path:'/phone/reword',
    component:()=>import('@/views/phone/membercenter-phone/reword.vue')
  }
]
const createRouter = () => new Router({
  mode:'history',
  routes,
  scrollBehavior: () => ({ y: 0 }) // 实现路由跳转之后滚动条滚到顶部。
})

const router = createRouter()


export default router
