import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css'
import QRCode from 'qrcode';
import '@/style/index.css'
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';
import store from '@/store'
import Vant from 'vant';
import 'vant/lib/index.css';
import { qqCodeCallback } from "@/api/login"
// import '@/permission'
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'NCpUMe3UhfeSusXwyY7eXHSzyIRnedZE'
// })
router.beforeEach((to, from, next) => {
  // 判断是否是手机端，如果是，返回true
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|ipad)/i)
  // Macintosh
  if (flag) { 
    console.log('true');
    // console.log(document.location.href, '路由的href，进入之前的');
    let url = document.location.href
    if (url.indexOf('?') > 0 && url.split("?").length > 1) {
      //登录返回token
      // console.log('有没有？');
      if (url.split("?")[1].split("=")[0] == "token") {
        //绑定
        store.commit("setToken", url.split("?")[1].split("=")[1]);
        Message({
          message: '登录成功',
          type: 'success',
          duration: 3 * 1000
        })
      } else if (url.split('?')[1] && url.split('?')[1].split('=')[0] == 'invitation') {
        //存储邀请码，就是页面刚刚从邀请链接进来的
        localStorage.setItem('invitation', url.split('?')[1].split('=')[1])
      }else if(url.split("?")[1].split("=")[1].split("&")[1] == "invitationqq"){
        //邀请的新用户
        localStorage.setItem('openidQQ',url.split("?")[1].split("=")[1].split("&")[0])
        localStorage.setItem('invitation', url.split("?")[1].split("=")[2])
      }
      //手机号被绑定
      if (url.split("?")[1].split("=")[0] == "repeat") {
        Message({
          message: '已被其他手机号绑定',
          type: 'waring',
          duration: 3 * 1000
        })
      }
      //qq登录有openid说明没有绑定手机号
      if (url.split("?")[1].split("=")[0] == "openid") {
        // console.log('openid', url.split("?")[1].split("=")[1].split("&")[0]);
        // this.$message("检测到您未绑定手机号,请先绑定手机号");
        localStorage.setItem('openidQQ', url.split("?")[1].split("=")[1].split("&")[0])
      }
      //刚刚开始调callback返回code
      if (url.split('?')[1] && url.split('?')[1].split('=')[0] == 'code') {
        let params = {
          code: url.split('?')[1].split('&')[0].split('=')[1],
        }
        if (localStorage.getItem('invitation')) {
          params.state = localStorage.getItem('invitation')
        } else {
          // params.state = url.split('?')[1].split('&')[1].split('=')[1]
          params.state = 'STATE'
        }
        localStorage.setItem('codeObj', JSON.stringify(params))
      }
    }
    //路径是否包含phone，不包含跳转手机端首页，包含直接放过
    if (to.fullPath.toLowerCase().indexOf('phone') == -1) {
      next('/phone')
    } else {
      if ((to.path == "/phone/membercenter" && !store.state.token) || (to.path == '/phone/musicScore/detail' && !store.state.token)) {
        Message({
          message: '请登录哦',
          type: 'error',
          duration: 3 * 1000
        })
        next('/phone/login')
        return
      } else {
        next()
      }
    }
  } else {
    if (to.fullPath.toLowerCase().indexOf('phone') != -1) {
      next('/')
    }
    next();
  }

});
Vue.config.productionTip = false
// 视频组件，全局注册
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
Vue.use(VideoPlayer);
Vue.config.productionTip = false
Vue.use(QRCode)
Vue.use(Share)
Vue.use(ElementUI);
Vue.use(Vant)
Vue.prototype.$image = '/api/public/file/'
// Vue.prototype.$url = '/api/public/file'
Vue.prototype.$src = '/api'
const req = require.context('@/components', true, /\.vue$/)
req.keys().forEach(key => {
  const comp = req(key).default
  Vue.component(comp.name, comp)
})
import plugins from '@/plugins'
Vue.use(plugins)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
