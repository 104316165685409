<template>
  <div class="phone-header">
    <!--  手寄端-->
    <div class="line">
      <div class="left">
        <img class="logoImg" :src="bacUrl" />
      </div>
      <div class="rightTop">
        <div class="centerContentBox">
          <div class="phoneStyle">
            <img
              src="@/assets/topPhone.png"
              style="width: 22px; height: 23px; margin-top: -5px"
            />
            {{ bottomList.phone }}
          </div>
          <div class="centerContent">
            <a @click="go(bottomList.tencent)" style="cursor: pointer">
              <img
                src="@/assets/topQQ2.png"
                style="
                  width: 20px;
                  height: 18px;
                  margin-top: -2px;
                  margin-right: 3px;
                "
              />
              <img
                src="@/assets/topWeixin2.png"
                style="
                  width: 20px;
                  height: 20px;
                  margin-top: 2px;
                  margin-right: 7px;
                "
              />{{ bottomList.tencent }}
            </a>
          </div>
        </div>
        <img
          src="@/assets/more.png"
          alt=""
          class="moreIcon"
          @click="moreClick"
        />
      </div>
    </div>
    <van-tabs
      sticky
      @click="TabClick"
      color="#1684fc"
      line-height="2px"
      line-width="56px"
      title-active-color="#1684fc"
      :active="activeRoute || 0"
    >
      <van-tab v-for="item in routeList" :key="item.id" :title="item.name">
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model="show" title="导航页">
      <div class="routeListBox">
        <div
          v-for="item in routeList"
          :key="item.id"
          class="routeNameItem"
          @click="routeItemClick(item.url)"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="bottomBox">
        <div class="titleA">客户服务中心</div>
        <div class="content">
          <div class="wx">
            <img src="@/assets/weixin.jpg" alt="" />
            <div>微信扫码或长按识别</div>
          </div>
          <div
            class="qq"
            @click="go(bottomList.tencent)"
            style="cursor: pointer"
          >
            <img src="@/assets/qq.png" alt="" />
            <div>
              <a @click="go(bottomList.tencent)" style="cursor: pointer"
                ><div>QQ在线点击咨询</div></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="footerBox">
        <div class="one" @click="SubmitAppointment">
          <div>预约咨询</div>
          <div>提交预约</div>
        </div>
        <div class="two">
          <div>电话咨询</div>
          <div>{{ bottomList.phone }}</div>
        </div>
        <div class="shareBox" @click="shareShow = !shareShow">
          <div>分享有惊喜</div>
          <div class="btn">分享</div>
        </div>
        <van-dialog title="分享列表" v-model="shareShow">
          <div style="height: 150px; padding: 20px 50px">
            <share></share>
          </div>
        </van-dialog>
      </div>
    </van-action-sheet>
    <el-dialog
      title="请填写预约内容"
      :visible.sync="centerDialogVisible"
      append-to-body
      center
      @close="closeD"
    >
      <el-form ref="ruleForm" :model="query" label-width="80px" :rules="rules">
        <el-form-item label="联系人" prop="nickName">
          <el-input
            v-model="query.nickName"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="query.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="notes">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="query.notes"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center; align-items: center">
        <el-button
          style="width: 100px; height: 30px; line-height: 0px"
          type="primary"
          @click="buttonClick('ruleForm')"
          >提交</el-button
        >
        <el-button
          style="width: 100px; height: 30px; line-height: 0px"
          @click="centerDialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMusicScore } from "@/api/musicScore";
import { mapState, mapMutations } from "vuex";
import {
  reservationApi,
  backgroundColor,
  bottomChange,
} from "@/api/musicScore";
export default {
  name: "phoneheader",
  data() {
    return {
      centerDialogVisible: false,
      activeRoute: Number(this.$route.query.id),
      query: {
        nickName: "",
        phone: "",
        notes: "",
      },
      rules: {
        nickName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern:
              /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        notes: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      show: false,
      shareShow: false,
      routeList: [
        { url: "/phone?id=0", id: 0, name: "首页" },
        { url: "/phone/createMusic?id=1", id: 1, name: "音乐制作" },
        { url: "/phone/musicComposition?id=2", id: 2, name: "曲谱排版" },
        { url: "/phone/bookComposition?id=3", id: 3, name: "图书排版" },
        { url: "/phone/plushing?id=4", name: "出版印刷", id: 4 },
        { url: "/phone/musicScore?id=5", id: 5, name: "曲谱库" },
        { url: "/phone/membercenter?id=6", id: 6, name: "会员服务" },
        { url: "/phone/recruit?id=7", id: 7, name: "招业务员" },
        { url: "/phone/about?id=8", id: 8, name: "关于我们" },
      ],
      bottomList: {},
      src: this.$image,
      bacUrl: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    $route() {
      this.activeRoute = Number(this.$route.query.id) || 0;
    },
  },
  created() {
    bottomChange().then((res) => {
      this.bottomList = res.data;
    });
    this.getLogo();
  },
  methods: {
    confirm() {
      this.shareShow = false;
    },
    // 获取顶部logo图
    getLogo() {
      getMusicScore({ plate: 21 }).then((res) => {
        if (res.data.picUrl) {
          this.bacUrl = this.$image + res.data.picUrl;
        } else {
          this.bacUrl = require("/src/assets/logo.jpg");
        }
      });
    },
    putKonge(Text) {
      const oneText = Text.toString();
      return oneText.replace(/ /g, "");
    },
    isIOS() {
      return /iphone|iPad/i.test(navigator.userAgent);
    },
    go(qqNum) {
      if (this.isIOS()) {
        document.location.href =
          "https://wpa.qq.com/msgrd?v=3&uin=" +
          this.putKonge(qqNum) +
          "&site=qqq&menu=yes";
      } else {
        window.open(
          "https://wpa.qq.com/msgrd?v=3&uin=" +
            this.putKonge(qqNum) +
            "&site=qqq&menu=yes"
        );
      }
    },
    closeD() {
      this.query = {
        nickName: "",
        phone: "",
        notes: "",
      };
    },
    ...mapMutations(["logout"]),
    routeItemClick(url) {
      this.$router.push(url);
      this.show = false;
    },
    goOut() {
      this.logout();
      this.$router.push("/");
      this.$message.success("退出登录成功");
    },
    moreClick() {
      this.show = true;
    },
    //点击标签导航页
    TabClick(name, title) {
      switch (title) {
        case "首页":
          this.$router.push("/phone?id=0");
          break;
        case "音乐制作":
          this.$router.push("/phone/createMusic?id=1");
          break;
        case "曲谱排版":
          this.$router.push("/phone/musicComposition?id=2");
          break;
        case "图书排版":
          this.$router.push("/phone/bookComposition?id=3");
          break;
        case "出版印刷":
          this.$router.push("/phone/plushing?id=4");
          break;
        case "曲谱库":
          this.$router.push("/phone/musicScore?id=5");
          break;
        case "会员服务":
          this.$router.push("/phone/membercenter?id=6");
          break;
        case "招业务员":
          this.$router.push("/phone/recruit?id=7");
          break;
        case "关于我们":
          this.$router.push("/phone/about?id=8");
          break;
      }
    },
    //点击提交预约
    SubmitAppointment() {
      this.centerDialogVisible = true;
      this.show = false;
    },
    buttonClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          reservationApi(this.query)
            .then(() => {
              this.centerDialogVisible = false;
              this.$message({
                showClose: true,
                message: "提交成功!",
                type: "success",
              });
            })
            .catch(() => {
              this.centerDialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/.van-tabs {
  .van-tabs__wrap {
    .van-tab {
      .van-tab__text {
        width: 60px;
        display: inline-block;
        text-align-last: justify;
      }
    }
  }
}

/deep/ .el-dialog {
  margin: 0 auto;
  width: 75% !important;
}
.bottomBox {
  margin-top: 20px;
  padding-left: 15px;
  .titleA {
    font-size: 18px;
    font-weight: 600;
  }
  .content {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .wx {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 110px;
        height: 110px;
      }
    }
    .qq {
      padding-top: 5px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:active {
        color: #7cb4e9;
      }
      img {
        width: 83px;
        height: 99px;
        margin-bottom: 5px;
      }
    }
  }
}
.footerBox {
  padding: 0 20px 5px;
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  .one {
    width: 20%;
    &:active {
      color: #7cb4e9;
    }
  }
  .two {
    text-align: center;
    width: 34%;
    // margin-left: 6%;
    &:active {
      color: #7cb4e9;
    }
  }
  .shareBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    .btn {
      width: 60%;
      background: #fff;
      color: #7cb4e9;
      text-align: center;
      border: 1px solid #eee;
      font-size: 13px;
      border-radius: 5px;
      &:active {
        background: #7cb4e9;
        color: #fff;
      }
    }
  }
  // justify-content: space-between;
  // align-items: center;
}
.phone-header {
  margin-bottom: 5px;
}
.van-action-sheet {
  min-height: 92% !important;
}
::v-deep .van-tabs__wrap {
  height: 29px !important;
}
::v-deep .van-tabs__wrap .van-tabs__nav--line.van-tabs__nav--complete {
  padding-left: 0 !important;
}

@media screen and (min-width: 700px) {
  .line {
    .left {
      .logoImg {
        width: 57% !important;
        object-fit: contain;
      }
    }
    .rightTop {
      width: 25% !important;
      .centerContentBox {
        margin-right: 8px;
      }
    }
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px 2px 10px;
  // background: rgba(248, 248, 248, 1);
  .left {
    width: 46%;
    .logoImg {
      height: 77px;
      width: 100%;
      object-fit: contain;
    }
  }
  .rightTop {
    width: 52%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .centerContentBox {
      min-width: 145px;
      margin-top: 5px;
    }
    .centerContent {
      color: #000;
      a {
        color: #000;
      }
    }
  }
}
.moreIcon {
  width: 3.2rem;
  height: 3.4rem;
}
.routeListBox {
  display: flex;
  flex-direction: column;
  .routeNameItem {
    padding-left: 15px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    width: 91px;
    display: inline-block;
    text-align-last: justify;
  }
}
</style>
