<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { bottomChange } from "@/api/musicScore";
export default {
  name: "App",
  components: {},
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      // document.oncontextmenu = new Function("event.returnValue=false");
      // // 2.禁用鼠标选中
      // // document.onselectstart = new Function("event.returnValue=false");
      // // 3.禁止键盘F12键
      // document.addEventListener("keydown", function (e) {
      //   if (e.key == "F12") {
      //     e.preventDefault(); // 如果按下键F12,阻止事件
      //   }
      // });
    });
  },
  mounted() {
    bottomChange().then((res) => {
      document.title = res.data.topTitle ? res.data.topTitle : "制谱网";
    });
    
  },
  beforeDestroy() {
    
  },
};
</script>

<style>
#app {
}
</style>
