var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.musicListObj.title)?_c('div',{staticClass:"lineBox1",style:({ width: _vm.width, height: _vm.height + 'px' })},[(_vm.musicListObj.title !='求谱榜')?_c('a',{staticClass:"topName",class:_vm.musicListObj.title == '求谱榜' ? 'musicText2' : 'musicText',attrs:{"href":_vm.musicListObj.title == '求谱榜'
        ? '#'
        : '/musicScoreDetails?rid=' +
          _vm.musicListObj.id +
          '&item=' +
          _vm.musicListObj.title +
          '&id=5',"target":"_blank"},on:{"click":function($event){return _vm.goToTop(_vm.musicListObj, _vm.musicListObj.title)}}},[_vm._v(" "+_vm._s(_vm.musicListObj.title)+" ")]):_c('a',{staticClass:"musicText2 topName",attrs:{"href":"javascript:;"}},[_vm._v(" "+_vm._s(_vm.musicListObj.title)+" ")]),_c('div',{staticClass:"musicList"},_vm._l((_vm.musicListObj.list),function(item,index){return _c('div',{key:index,staticClass:"musicItem"},[(_vm.musicListObj.title != '求谱榜')?_c('a',{staticClass:"singName",attrs:{"href":'/musicScore/musicDetails?id=5&rid=' + item.id,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('a',{staticClass:"singName2",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(item.name))]),_c('div',{class:_vm.musicListObj.title == '求谱榜' ? 'userName2' : 'userName'},[_vm._v(" "+_vm._s(item.lyrics)+" ")])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }