//将一维数组转换为二维数组
export const setTwoDimensionalArray = (list,num) => {
    const listResult = [];  // 最终返回的二维数组
    const count = num;
    let i = 0;
    let tempArr = [];
    list.forEach(e =>{
      i ++;
      tempArr.push(e);
      if(i%count === 0 || list.length === i){
        listResult.push(tempArr);
        tempArr = [];
      }
    })
    return listResult;
}

//单击图片放大
export const showImagePreview= (url)=> {
  const image = new Image();
  image.src = url;
  image.onload = () => {
    // 创建弹出层
    const previewContainer = document.createElement('div');
    previewContainer.style.position = 'fixed';
    previewContainer.style.top = 0;
    previewContainer.style.bottom = 0;
    previewContainer.style.left = 0;
    previewContainer.style.right = 0;
    previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
    previewContainer.style.display = 'flex';
    previewContainer.style.justifyContent = 'center';
    previewContainer.style.alignItems = 'center';
    document.body.appendChild(previewContainer);
    // 在弹出层中添加图片
    const previewImage = document.createElement('img');
    previewImage.src = url;
    previewImage.style.maxWidth = '80%';
    previewImage.style.maxHeight = '80%';
    previewContainer.appendChild(previewImage);
    // 点击弹出层，关闭预览
    previewContainer.addEventListener('click', () => {
      document.body.removeChild(previewContainer);
    });
  };
}
