import Vue from 'vue'
import Vuex from 'vuex'
import { getItem, setItem,removeItem } from '@/utils/storage'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        musicalType:getItem('musicalType')||[],
      token:getItem('token')||'',
      userInfo:getItem('userInfo')||''
    },
    mutations: {
        setMusicalType(state, value){
            state.musicalType = value
            setItem('musicalType',value)
        },
      setToken (state, data) {
          state.token = data
          setItem('token',data)
      },
      setUserInfo(state,data){
        state.userInfo = data
        setItem('userInfo',data)
      },
      logout(state){
        state.token=''
        state.userInfo = ''
        removeItem('token')
        removeItem('userInfo')
      }
    },
    actions: {
    },
    modules: {
    }
  })
