import request from '@/service/index'
//查询首页图列表
export function getMusicBannerReceptionListApi() {
    return request({
        url: '/music/banner/receptionList',
        method: 'get'
    })
}
//查询公司信息
export function getMusicCompanyGetCompanyApi() {
    return request({
        url: '/music/company/getCompany',
        method: 'get'
    })
}
//查询首页顶部插图
export function getMusicBannerIndexPicApi() {
    return request({
        url: '/music/banner/indexPic',
        method: 'get'
    })
}
//查询图片
export function imgList(){
    return request({
        url:'/music/banner/contact',
        method:'get'
    })
}
