<template>
    <div>
      <canvas :id="ids" :style="{width: width,height: height}"></canvas>
    </div>
  </template>
  
  <script>
  import QRCode from 'qrcode'
  export default {
      name:'qrcodes',
      props:{
          width:{
              type:String,Number,
              default:()=>'100'
          },
          height:{
              type:String,Number,
              default:()=>'100'
          },
          qrUrl:{
              type:String,
              require:true
          },
          ids:{
            type:String,
            require:true
          }
      },
      created(){
        
      },
      mounted(){
        this.getQRCode()
          console.log(this.qrUrl,'url');
      },
      methods:{
          getQRCode() {  
          let that = this
      //生成的二维码为URL地址js
      // this.qrUrl= ;
        let opts = {
          errorCorrectionLevel: "H", //容错级别
          type: "image/png", //生成的二维码类型
          quality: 0.3, //二维码质量
          margin: 0, //二维码留白边距
          width: this.width, //宽
          height: this.height, //高
          text: this.qrUrl, //二维码内容
          color: {
            dark: "#333333", //前景色
            light: "#fff", //背景色
          },
        };
        
        let msg = document.getElementById(that.ids);
        // 将获取到的数据（val）画到msg（canvas）上
        QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
          if (error) {
            // console.log("二维码加载失败", error);
            that.$message.error("二维码加载失败");
          }
        });
      },
      }
  
  }
  </script>
  
  <style>
  
  </style>