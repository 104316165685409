import request from '@/service/index'
//查询曲谱库第一行
export function getMusicLibraryOneListLibraryApi() {
    return request({
        url: '/music/library/onelistLibrary',
        method: 'get'
    })
}
//查询曲谱库第二行
export function getMusicLibraryTwoListLibraryApi() {
    return request({
        url: '/music/library/twolistLibrary',
        method: 'get'
    })
}
//查询曲谱库第三行
export function getMusicLibraryThreeLibraryApi() {
    return request({
        url: '/music/library/threeLibrary',
        method: 'get'
    })
}
//曲谱库分类
export function getMusicLibraryClassifyApi() {
    return request({
        url: '/music/library/classify',
        method: 'get'
    })
}
//查询曲谱
export function getMusicLibraryInquireApi(params) {
    return request({
        url: '/music/library/inquire',
        method: 'get',
        params
    })
}
///获取曲谱音乐视频图书详情
export function getMusicWordApi(params) {
    return request({
        url: `/music/musicWord/getDetails/${params}`,
        method: 'get',
    })
}
//新增收藏
export function postMusicCollect(data) {
    return request({
        url: `/music/collect`,
        method: 'post',
        data
    })
}
//新增预约信息
export function reservationApi(data) {
    return request({
        url: `/add`,
        method: 'post',
        data
    })
}
//背景颜色
export function backgroundColor() {
    return request({
        url: `/music/background/color`,
        method: 'get'
    })
}
//更多曲谱
export function getMusicLibraryMoreApi(params) {
    return request({
        url: `/music/library/more`,
        method: 'get',
        params
    })
}
// 查询会员权限下拉框
export function getSelectGradeDrop(){
    return request({
        url: '/select/gradeDrop',
        method: 'get'
    })
}
//查询图片
export function imgList(){
    return request({
        url:'/music/banner/musicIllustration',
        method:'get'
    })
}
//动态底部
export function bottomChange(){
    return request({
        url:'/music/bottom/bottom',
        method:'get'
    })
}
// 获取移动端曲谱库修饰图还有首页头部logo
export function getMusicScore(params){
    return request({
        url:'/music/banner/getPicByPlate',
        method:'get',
        params
    })
}