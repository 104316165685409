<template>
  <div
    v-if="musicListObj.title"
    class="lineBox1"
    :style="{ width: width, height: height + 'px' }"
  >
    <a
    v-if="musicListObj.title !='求谱榜'"
      :href="
        musicListObj.title == '求谱榜'
          ? '#'
          : '/musicScoreDetails?rid=' +
            musicListObj.id +
            '&item=' +
            musicListObj.title +
            '&id=5'
      "
      target="_blank"
      class="topName"
      :class="musicListObj.title == '求谱榜' ? 'musicText2' : 'musicText'"
      @click="goToTop(musicListObj, musicListObj.title)"
    >
      {{ musicListObj.title }}
    </a>
    <a href="javascript:;" class="musicText2 topName" v-else>
      {{ musicListObj.title }}
    </a>
    <div class="musicList">
      <div
        class="musicItem"
        v-for="(item, index) in musicListObj.list"
        :key="index"
      >
        <a
        v-if="musicListObj.title != '求谱榜'"
          :href="
           '/musicScore/musicDetails?id=5&rid=' + item.id
          "
          target="_blank"
          class="singName"
        >
          {{ item.name }}
        </a>
        <a href="javascript:;" class="singName2" v-else>{{ item.name }}</a>
        <div :class="musicListObj.title == '求谱榜' ? 'userName2' : 'userName'">
          {{ item.lyrics }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "musicListItem",
  props: ["musicListObj", "width", "height"],
  computed: {
    ...mapState(["token"]),
  },
  data() {
    return {
      datalist: [],
    };
  },
  methods: {
    goToTop(item, text) {
      if (text == "求谱榜") {
        return;
      }
      this.$emit("goCatelog", item.id, item.title);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0, //回到底部
          behavior: "smooth", //smooth 平滑；auto:瞬间
        });
      });
    },
    goDetails(item, text) {
      if (text == "求谱榜") {
        return;
      }
      window.open(`/musicScore/musicDetails?rid=${item.id}`)
      // this.$router.push({
      //   path: "/musicScore/musicDetails",
      //   query: {
      //     rid: item.id,
      //   },
      // });
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.musicText:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #4a9ef8;
}
.lineBox1 {
  margin-top: 15px;
  font-size: 14px;
  //   width: 22%;
  border: 1px solid #d5d5d5;
  height: 350px;
  padding: 10px 5px 20px 10px;
  .topName {
    display: block;
    margin-bottom: 6px;
    text-align: center;
    font-weight: 700;
    font-size: 17px;
  }
  .musicList {
    .musicItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      height: 30px;
      .singName {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .singName:hover {
        cursor: pointer;
        color: #4a9ef8;
        text-decoration: underline;
      }
      .userName {
        width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .userName:hover {
        cursor: pointer;
        color: #4a9ef8;
        text-decoration: underline;
      }
      .userName2 {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 4px;
      }
      .singName2 {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 4px;
      }
    }
  }
}
</style>
