<template>
  <div class="payList">
    <div v-if="yuerShow">
      <div v-for="item in payList" :key="item.name" class="payItem">
        <div class="left">
          <img :src="item.icon" alt="" />
          <div>{{ item.name }}</div>
        </div>
        <input
          type="radio"
          name="select"
          :value="item.name"
          v-model="payType"
        />
      </div>
    </div>
    <div v-else>
      <div v-for="item in payList2" :key="item.name" class="payItem">
        <div class="left">
          <img :src="item.icon" alt="" />
          <div>{{ item.name }}</div>
        </div>
        <input
          type="radio"
          name="select"
          :value="item.name"
          v-model="payType"
        />
      </div>
    </div>

    <div class="bottom">
      <div>金额：{{ money }}</div>
      <div class="paybtn" @click="pay">支付</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pay-list",
  data() {
    return {
      payList: [
        { name: "微信支付", icon: require("@/assets/phoneImg/wx.png") },
        { name: "支付宝支付", icon: require("@/assets/phoneImg/zfb.png") },
        { name: "余额支付", icon: require("@/assets/phoneImg/ye.png") },
      ],
      payList2: [
        { name: "微信支付", icon: require("@/assets/phoneImg/wx.png") },
        { name: "支付宝支付", icon: require("@/assets/phoneImg/zfb.png") },
      ],
      payType: "",
    };
  },
  props: ["money", "yuerShow"],
  methods: {
    pay() {
      if (this.payType == "") {
        return this.$toast.fail("请选择支付方式");
      }
      // if(!this.money){
      //     return this.$toast.fail('请输入金额后操作')
      // }
      if (this.payType == "微信支付") {
        this.$emit("click", 1);
      } else if (this.payType == "支付宝支付") {
        this.$emit("click", 2);
      } else {
        this.$emit("click", 3);
      }
    },
  },
};
</script>

<style scoped lang="less">
.payList {
  padding: 30px 26px;
  .payItem {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
    }
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .paybtn {
      width: 103px;
      height: 31px;
      line-height: 31px;
      border-radius: 17px;
      text-align: center;
      color: #fff;
      background: #2c386a;
    }
  }
}
</style>