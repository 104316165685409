import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router' // 假设你的路由文件位于src/router/index.js
const service = axios.create({
    baseURL: '/api',
    timeout: 60000 // 过期时间
})

// request interceptor
service.interceptors.request.use(config => {
    const token = store.state.token
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
},
    error => {
        return Promise.reject(error)
    }
)

let showMsg = false
// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == 401) {
            if (!showMsg) {
                router.push({
                    path: '/logon',
                })
                store.commit('logout')
                showMsg = true
                setTimeout(()=>{
                    showMsg = false
                },1000)
                Message({
                    message: '请登录哦',
                    type: 'error',
                    duration: 3 * 1000
                })
                return Promise.reject(new Error(res.msg))
            }

            //订单未支付
        } else if (res.code == 40001) {
            return res
        } else if (res.code != 200) {
            Message({
                message: res.msg || '网络开小差了，请稍后再试',
                type: 'error',
                duration: 3 * 1000
            })
            return Promise.reject(new Error(res.msg))
        } else {
            return res
        }

    },
    error => {
        Message({
            message: error.msg,
            type: 'error',
            duration: 3 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
