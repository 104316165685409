<template>
  <div class="itemCarousel">
    <el-carousel
      trigger="click"
      :autoplay="false"
      height="466px"
      :indicator-position="list.length > 9 ? 'outside' : 'none'"
    >
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="imgList">
          <img
            v-for="(i,index) in item"
            class="imgA"
            :key="i.id"
            :src="src + i.url"
            alt=""
            @click="topImgPreview(item,index)"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <van-image-preview
      v-model="ListShow"
      :images="PreviewList"
      @change="onChange"
      :startPosition="Imgindex"
    >
    </van-image-preview>
  </div>
</template>

<script>
import { setTwoDimensionalArray } from "@/utils";
export default {
  name: "phoneCarusel",
  data() {
    return {
      dataList: [],
      ListShow: false,
      src: this.$image,
      previewImgList: [],
      PreviewList:[],
      Imgindex: 0,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if (this.list.length > 0) {
      this.dataList = setTwoDimensionalArray(this.list, 9);
      // this.dataList.map((item) => {
      //   item.map((items) => {
      //     this.PreviewList.push(this.$image + items.url)
      //   });
      // });
      this.dataList[0].forEach(item=>{
        this.PreviewList.push(this.$image + item.url)
      }
      )
    }
  },
  methods: {
    onChange(index) {
      this.Imgindex = index;
    },
    topImgPreview(list,index) {
      this.Imgindex = index;
      this.ListShow = true;
      this.PreviewList = this.list.map(item=>{
        return this.src+item.url
      })
    },
  },
};
</script>

<style scoped lang="less">
.imgList {
  display: flex;
  flex-wrap: wrap;
  .imgA {
    margin-top: 10px;
    margin-left: 2%;
    width: 30.5%;
    height: 145px;
    box-shadow: 0 0 8px #e6e6e6;
  }
}
@media screen and (min-width: 700px) {
  
  .itemCarousel /deep/.el-carousel .el-carousel__container{
    height: 907px!important;
    // border: 1px solid red;
  }
  .imgList{
    .imgA{
      height: 285px;
      
    }
  }
}
.el-carousel {
  margin-bottom: 20px;
}
/deep/.el-carousel__arrow--left {
  display: none !important;
}
/deep/.el-carousel__arrow--right {
  display: none !important;
}
/deep/ .el-carousel__indicators {
  .el-carousel__indicator,
  .el-carousel__indicator--horizontal {
    margin-top: 6px;
    padding: 6px 5px;
    .el-carousel__button {
      width: 15px;
      height: 15px !important;
      border-radius: 50%;
    }
  }
}
</style>